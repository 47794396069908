.pokemon-list-page {
  --shadow-soft: rgba(0, 0, 0, 0.1);
  --page-header-height: 60px;
  --page-content-padding: 48px;

  height: var(--content-height);
  width: var(--content-width);
  background-color: rgb(246, 249, 250);
  padding: var(--page-content-padding);
  overflow: hidden;

  .page-header {
    height: var(--page-header-height);
    width: 100%;
    padding-bottom: 48px;
    // padding: 24px 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
      font-weight: 600;
    }

    .search-container {
      height: 40px;
      width: 40%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      background-color: var(--white);
      border-radius: 20px;
      box-shadow: 0px 1px 4px 0px var(--shadow-soft);

      .icon {
        color: var(--lightGray);
      }

      .search-bar {
        height: 40px;
        width: 100%;
        padding-left: 12px;
        font-size: 16px;
        border: none;
        outline: none;

        &::placeholder {
          color: var(--lightGray);
        }
      }
    }
  }

  .pokemon-list {
    --grid-gap: 28px;
    height: calc(var(--content-height) - var(--page-header-height) - var(--page-content-padding));
    width: var(--content-width);
    margin-left: calc(-1 * var(--page-content-padding));
    padding: var(--page-content-padding);
    padding-top: 24px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--grid-gap);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }

    .card {
      height: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      box-shadow: 0px 0px 32px 1px var(--shadow-soft);
      background-color: var(--white);
      min-width: 240px;
      overflow: hidden;
      user-select: none;

      .image-container {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: lightpink;

        .image {
          height: 240px;
        }
      }

      .info-container {
        position: relative;
        height: 120px;
        width: 100%;
        display: flex;
        padding: 0px 24px;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: var(--white);
        box-shadow: 0px -1px 4px 0px var(--shadow-soft);

        .name-id-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .name {
            font-size: 24px;
            font-weight: 600;
          }

          .id {
            font-size: 18px;
            color: var(--gray);
          }
        }
        .chip-description-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: baseline;

          .chips-container {
            display: flex;
            width: 50%;
            justify-content: flex-start;

            .chip {
              height: 100%;
              padding: 6px 16px;
              margin-right: 8px;
              border-radius: 28px;
              background-color: #aaa;
              box-shadow: 0px 1px 4px 1px var(--shadow-soft);

              .text {
                color: var(--white);
                font-size: 12px;
                font-weight: 500;
                box-shadow: 0px 0px 1px 0px var(--shadow-soft);
              }
            }
          }

          .description {
            // width: 50%;
            font-size: 12px;
            font-weight: 500;
            color: var(--gray);
          }
        }
      }
    }
    a:last-child {
      margin-bottom: var(--page-content-padding);
    }
  }
}

//246, 249, 250
