.header {
  position: relative;
  height: var(--header-height);
  background-color: var(--pokedex-red);

  .profile-icon {
    height: 100%;
    margin-right: calc(var(--header-height) / 2);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--gray);

    .profile-circle {
      width: 42px;
      height: 42px;
      margin-top: -2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--white);
      border-radius: 50%;
      box-shadow: 0px 1px 3px 1px var(--shadow);
    }
  }
}
