.sidebar {
  position: relative;
  width: var(--sidebar-width);
  height: var(--content-height);
  align-items: flex-start;
  background-color: var(--white);
  box-shadow: 1px 0px 6px 1px var(--shadow);
  padding: 0 var(--sidebar-padding);

  .section-container {
    padding-bottom: 0%;
    margin-bottom: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .section-header {
      margin: 42px 0px 8px 0px;
      color: #aaa;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .section-link {
      width: calc(100% - (2 * var(--sidebar-padding)));
      height: 28px;
      padding: 2px 16px;
      display: flex;
      align-items: center;
      border-radius: 16px;

      .link-text {
        margin-top: -1px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
