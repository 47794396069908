html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  color: var(--darkGray);
}
* {
  box-sizing: border-box;
  font-family: “Fira Sans”, -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Oxygen, Ubuntu, Cantarell,
    “Open Sans”, “Helvetica Neue”, sans-serif;
}
a {
  text-decoration: none;
  color: initial;
}
