@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@400;500;600;700&display=swap");
html {
  --main: #2f4858;
  --secondary: #33658a;
  --positive: #2ed573;
  --negative: #ff4757;
  --green: #7bed9f;
  --orange: #ff7f50;
  --purple: #70a1ff;
  --black: #000;
  --white: #fff;
  --lightGray: #dfe4ea;
  --mediumGray: #b9b9b9;
  --gray: #757575;
  --darkGray: hsl(205, 37%, 20%);
  --sidebar-background: #fff;
  --background: #ebf0f3aa;
  --pokedex-red: rgb(197, 0, 36);
  --shadow: rgba(0, 0, 0, 0.2);

  --fire: hsl(24, 89%, 50%);
  --grass: hsl(101, 86.4%, 28.8%);
  --water: hsl(209, 76.6%, 38.6%);
  --normal: hsl(57, 23.2%, 67.8%);
  --flying: hsl(174, 37.1%, 49.2%);
  --bug: hsl(80, 66.7%, 41.2%);
  --poison: hsl(283, 100%, 40.4%);
  --electric: hsl(59, 91.3%, 27.1%);
  --ground: hsl(53, 65.8%, 56.5%);
  --fighting: hsl(354, 100%, 43.9%);
  --psychic: hsl(339, 100%, 44.7%);
  --rock: hsl(46, 34.7%, 28.8%);
  --ice: hsl(186, 65.5%, 34.1%);
  --ghost: hsl(282, 40.7%, 41%);
  --dragon: hsl(260, 97.1%, 59.6%);
  --dark: hsl(25, 33.3%, 22.4%);
  --steel: hsl(164, 8.3%, 44.9%);
  --fairy: hsl(337, 88.6%, 75.9%);

  --fire-light: hsl(24, 89%, 80%);
  --grass-light: hsl(101, 86.4%, 88.8%);
  --water-light: hsl(209, 76.6%, 78.6%);
  --normal-light: hsl(57, 23.2%, 87.8%);
  --flying-light: hsl(174, 37.1%, 86.2%);
  --bug-light: hsl(80, 66.7%, 81.2%);
  --poison-light: hsl(283, 100%, 90.4%);
  --electric-light: hsl(59, 91.3%, 87.1%);
  --ground-light: hsl(53, 65.8%, 85.5%);
  --fighting-light: hsl(354, 100%, 73.9%);
  --psychic-light: hsl(339, 100%, 79.7%);
  --rock-light: hsl(46, 34.7%, 68.8%);
  --ice-light: hsl(186, 65.5%, 84.1%);
  --ghost-light: hsl(282, 40.7%, 71%);
  --dragon-light: hsl(260, 97.1%, 84.6%);
  --dark-light: hsl(25, 33.3%, 62.4%);
  --steel-light: hsl(164, 8.3%, 44.9%);
  --fairy-light: hsl(337, 88.6%, 92.9%);
}
