html {
  --header-height: 64px;
  --sidebar-width: 200px;
  --content-height: calc(100vh - var(--header-height));
  --content-width: calc(100vw - var(--sidebar-width));
  --sidebar-padding: 20px;
}
* {
  box-sizing: border-box;
}
